<!-- eslint-disable max-len -->
<template>
    <div class="home">
        <!-- <div class="search-form">
      <input class="input" type="text" placeholder="Search any Ethereum address">
    </div> -->
        <div v-if="isLoading" class="placeholder">
            {{ displayMessage }}
        </div>
        <div v-else class="container">
            <h3>{{ displayWalletAddress() }}</h3>
            <h5>
                <div v-if="walletIsLoading">≈ ...</div>
                <div v-else>
                    {{ displayWalletBalance() }}
                </div>
            </h5>
            <div class="gallery">
                <!-- eslint-disable-next-line max-len -->
                <div
                    v-for="(item, index) in walletData.items"
                    :key="index"
                    class="item"
                >
                    <div class="wrapper">
                        <a class="item" :href="item.link" target="_blank">
                            <img class="image" :src="item.image_small" />
                            <div class="price">
                                <div v-if="walletIsLoading">...</div>
                                <div v-else>Ξ{{ item.price }}</div>
                            </div>
                        </a>
                        <div class="name">{{ item.name || " " }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import web3 from "web3";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useHead } from "@vueuse/head";

export default {
    setup() {
        const route = useRoute();

        const isLoading = ref(true);
        const displayMessage = ref("Loading NFTs for a wallet...");
        const walletAddress = ref("");
        const walletBalance = ref(0);
        const walletData = ref({});
        const walletIsLoading = ref(true);

        const defaultEthereumPrice = 2500;
        const ethUsdPrice = ref(defaultEthereumPrice);

        const addressIsValid = (address) => {
            try {
                // eslint-disable-next-line no-unused-vars
                const fixedAddress = web3.utils.toChecksumAddress(
                    address.toLowerCase()
                );
                return true;
            } catch (err) {
                return false;
            }
        };

        const displayWalletAddress = () => {
            const address = walletAddress.value;
            return `${address.substring(0, 6)}...${address.substring(
                address.length - 4
            )}`;
        };

        const displayPrice = (value, shortFormat = false) => {
            if (shortFormat) {
                let newValue = value;
                const suffixes = ["", "K", "M", "B", "T"];
                let suffixNum = 0;
                while (newValue >= 1000) {
                    newValue /= 1000;
                    suffixNum += 1;
                }
                newValue = newValue.toPrecision(3);
                if (newValue.endsWith(".0")) {
                    newValue = newValue.slice(0, -2);
                }

                return `$${newValue}${suffixes[suffixNum]}`;
            }

            return new Intl.NumberFormat("en-EN", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
            }).format(value);
        };

        const displayWalletBalance = () => {
            const balance = walletBalance.value;
            // TODO: get ETH price
            return `≈ ${balance.toFixed(2)} ETH (${displayPrice(
                balance * ethUsdPrice.value
            )})`;
        };

        const initWallet = async () => {
            const res = await axios.get(
                `https://api.opensea.io/api/v1/assets?owner=${walletAddress.value}&format=json`
            );

            if (res.data.assets) {
                const items = {};
                const collections = {};
                // eslint-disable-next-line no-restricted-syntax, guard-for-in
                for (const asset of res.data.assets) {
                    if (asset.name !== null) {
                        if (
                            asset.asset_contract.address in collections ===
                            false
                        ) {
                            collections[asset.asset_contract.address] = {
                                name: asset.collection.name,
                                image: asset.asset_contract.image_url,
                                slug: asset.collection.slug,
                                floorPrice: 0,
                                itemsCount: 1,
                                itemsContracts: [],
                            };
                        } else {
                            collections[
                                asset.asset_contract.address
                            ].itemsCount += 1;
                        }

                        collections[
                            asset.asset_contract.address
                        ].itemsContracts.push(asset.id);

                        let price = 0;
                        // TODO: support WETH & USDC
                        // eslint-disable-next-line no-prototype-builtins
                        if (
                            asset.num_sales > 0 &&
                            asset.hasOwnProperty("last_sale") &&
                            asset.last_sale.payment_token.id === 1
                        ) {
                            price = asset.last_sale.total_price / 1e18;
                        }

                        items[asset.id] = {
                            id: asset.id,
                            collection: asset.asset_contract.address,
                            name: asset.name, // `${asset.collection.name} #${asset.token_id}`,
                            link: asset.permalink,
                            image_small: asset.image_preview_url,
                            image_original: asset.image_original_url,
                            lastPrice: price,
                            price,
                        };
                    }

                    // eslint-disable-next-line max-len
                    if (
                        ethUsdPrice.value === defaultEthereumPrice &&
                        asset.last_sale !== null &&
                        asset.last_sale.payment_token.id === 1
                    ) {
                        // eslint-disable-next-line max-len
                        ethUsdPrice.value =
                            asset.last_sale.payment_token.usd_price /
                            asset.last_sale.payment_token.eth_price;
                    }
                }

                // TODO: sort items by name or by price

                walletData.value = {
                    collections,
                    items,
                };
            }

            isLoading.value = false;
        };

        const calculatePortfolioValue = async () => {
            // eslint-disable-next-line no-unused-vars
            const { collections, items } = walletData.value;
            let totalCost = 0;

            // get collections floor prices
            // eslint-disable-next-line no-restricted-syntax, guard-for-in
            for (const key in collections) {
                const collection = collections[key];
                // eslint-disable-next-line no-await-in-loop
                const collectionData = await axios.get(
                    `https://api.opensea.io/api/v1/collection/${collection.slug}/stats?format=json`
                );
                const collectionFloorPrice =
                    collectionData.data.stats.floor_price || 0;
                collections[key].floorPrice = collectionFloorPrice;

                // eslint-disable-next-line no-restricted-syntax, guard-for-in
                for (const id of collections[key].itemsContracts) {
                    const { lastPrice } = items[id];
                    let itemPrice = 0;
                    // TODO: use asset.creator.config = 'verified'
                    if (lastPrice > collectionFloorPrice * 15) {
                        itemPrice = lastPrice;
                    } else {
                        itemPrice = collectionFloorPrice;
                    }

                    items[id].price = itemPrice;
                    totalCost += itemPrice;
                }
                // totalCost += (collectionFloorPrice * collection.itemsCount);
            }

            walletBalance.value = totalCost;
            walletIsLoading.value = false;
        };

        onMounted(async () => {
            // get coingecko Ethereum price
            ethUsdPrice.value =
                (
                    await axios.get(
                        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=USD"
                    )
                ).data.ethereum.usd || 0;

            // set wallet address
            const address = route.params.wallet || "";
            if (address !== "" && addressIsValid(address)) {
                walletAddress.value = address;
                // get collectibles
                await initWallet();
                // set floor prices
                await calculatePortfolioValue();
            } else {
                displayMessage.value = "Wallet address is not valid!";
                // isLoading.value = false;
            }
        });

        useHead({
            title: computed(() => `${displayWalletAddress()} | mynfts.gallery`),
            // meta: [
            //   {
            //     name: `description`,
            //     content: computed(() => siteData.description),
            //   },
            // ],
        });

        return {
            displayMessage,
            isLoading,
            displayWalletAddress,
            displayWalletBalance,
            walletAddress,
            walletBalance,
            walletData,
            walletIsLoading,
        };
    },
};
</script>

<style lang="scss" scoped>
.placeholder {
    margin-top: 20px;

    font-size: 18px;
    font-weight: 400;
}

.container {
    h3 {
        margin-top: 30px;
    }

    h5 {
        margin: 0;

        color: #585969;
    }

    .search-form {
        > .input {
            width: 365px;
            height: 30px;

            background-color: rgb(222, 222, 240);
            border-radius: 10px;
            border: none;
        }
    }
    .gallery {
        padding: 30px 10px;

        > .item {
            // width: calc(((100vw - 403px) - 30px) / 2);
            // height: calc(((100vw - 403px) - 30px) / 2);

            margin: 15px 15px 0;
            vertical-align: top;

            display: inline-block;
            width: 252px;
            height: 295px;

            background-color: transparent;

            > .wrapper {
                position: relative;

                width: 100%;
                height: 100%;

                background-color: transparent;
                border-radius: 30px;

                > .item {
                    position: relative;

                    display: block;

                    cursor: pointer;

                    // removed while there is not option to view the item info
                    &:hover {
                        > .image,
                        > .price {
                            transform: scale(1.05);
                        }
                    }

                    > .image {
                        width: 252px;
                        height: 252px;

                        border-radius: 30px;
                        box-shadow: rgb(37 41 46 / 20%) 0px 10px 30px;

                        object-fit: cover;
                        transition: all 0.125s ease 0s;
                    }

                    > .price {
                        position: absolute;
                        top: 12px;
                        right: 12px;

                        padding: 3px 7px;

                        color: #313138;
                        font-size: 12px;
                        font-weight: 700;

                        background-color: rgba(255, 255, 255, 0.4);
                        border-radius: 12px;

                        cursor: default; // fix for .wrapper hover

                        transition: all 0.125s ease 0s;
                    }
                }

                > .name {
                    padding: 10px 3px 15px;

                    color: rgb(83, 83, 112);
                    font-size: 14px;
                    font-weight: 700;
                    letter-spacing: 0.5px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}
</style>
