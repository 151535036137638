<template>
    <div id="nav">
        <div class="banner">
            This domain is for sale, contact me on Telegram:
            <a href="https://t.me/etofej" target="_blank">@etofej</a>
        </div>
    </div>
    <router-view />
</template>

<style lang="scss">
html,
body,
#app {
    height: 100%;
}

#app {
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    font-family: "Poppins", sans-serif;
    font-display: swap;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

body {
    background-color: #e5ecf5;
}
</style>

<style lang="scss" scoped>
.banner {
    padding: 10px;

    color: #253159;
    font-size: 16px;

    background-color: #aabbda;

    a,
    a:visited {
        color: #222e9c;
        text-decoration: underline;
    }
}
</style>
