<template>
    <div class="center-container">
        <div class="center-inner">
            <h1>mynfts.gallery</h1>
            <!-- <form @submit.prevent="handleAddress" class="main-form">
                <input
                    type="text"
                    placeholder="Enter Ethereum wallet address"
                    v-model="walletAddress"
                    class="input"
                />
            </form> -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';

import { ref } from "vue";
import { useRouter } from "vue-router";
import web3 from "web3";
import namehash from "@ensdomains/eth-ens-namehash";
// eslint-disable-next-line no-unused-vars
import { formatsByName, formatsByCoinType } from "@ensdomains/address-encoder";
// eslint-disable-next-line no-unused-vars
import { request, gql, batchRequests } from "graphql-request";

export default {
    name: "Home",
    setup() {
        const router = useRouter();
        const walletAddress = ref("");

        const addressIsValid = (address) => {
            try {
                // eslint-disable-next-line no-unused-vars
                const fixedAddress = web3.utils.toChecksumAddress(
                    address.toLowerCase()
                );
                return true;
            } catch (err) {
                return false;
            }
        };

        const handleAddress = async () => {
            // 0x48DaDE42f48CCE278A60544641A11D36492B49e0
            // cryptofey.eth

            // eslint-disable-next-line prefer-const
            let address = walletAddress.value;

            if (address.endsWith(".eth")) {
                console.log("ENS");
                const hash = namehash.hash(namehash.normalize(address));
                console.log(hash);
                // const ensDomainId = formatsByName.ETH.decoder(hash).toString('hex');
                // console.log(ensDomainId);
                // https://thegraph.com/hosted-service/subgraph/ensdomains/ens?query=Example%20query

                const query = gql`
                    {
                        domains(where: { id: $id }) {
                            id
                            resolvedAddress {
                                id
                            }
                        }
                    }
                `;
                const res = await request(
                    "https://api.thegraph.com/subgraphs/name/ensdomains/ens",
                    query,
                    {
                        id: hash,
                    }
                );
                console.log("DOMAIN", res);
                // const resQ = await batchRequests('https://api.thegraph.com/subgraphs/name/ensdomains/ens', [
                //   {
                //     query: `
                //       domains(where: {id: $id}) {
                //         id
                //         resolvedAddress {
                //           id
                //         }
                //       }
                //     `,
                //   },
                //   {
                //     variables: {
                //       id: hash,
                //     },
                //   },
                // ]);
                // console.log('DOMAIN', resQ);
            }

            if (addressIsValid(address)) {
                router.push({
                    name: "Wallet",
                    params: {
                        wallet: address,
                    },
                });
            } else {
                console.error("NOT VALID");
            }
        };

        return {
            handleAddress,
            walletAddress,
        };
    },
};
</script>

<style lang="scss" scoped>
.center-container {
    display: flex;
    height: 100%;
}

.center-inner {
    display: flex;
    width: 100%;

    justify-content: center;
    align-items: center;
    align-self: center;
    flex-direction: column;
}

.main-form {
    margin-top: 20px;

    display: flex;
}
</style>
